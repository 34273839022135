//A component capable of reading in a STRING from *.json and displaying as a <GatsbyImage>
// This can use a dynamic string, and the approach is an inverse to a Static Image
// requires: GraphQL on parent component
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
//only imported to infer type information
import demoData from "../text/demolitionPageData.json";
import React from "react";

//using GraphQL ServicePageQuery as a template for props, plus demoData
type ComponentLocalT = PageProps<Queries.ServicePageQuery> & { pageContext: typeof demoData };

//Type FileNode to Type ImageDataLike
const imgTransform = (myImage: ComponentLocalT["data"]["allFile"]["edges"][0]) => {
  const x: ImageDataLike = {
    ...myImage.node,
    //TS getImage() wants thing or undefined, not: thing or null (.childImageSharp may be null)
    childImageSharp: myImage.node.childImageSharp || undefined,
    //old:
    //layout: "fixed",
    //height: 650,
  };
  return getImage(x);
};

//WARN image path root is implied: ../../images/
//assume *PageData.json has "headerImage": "services/TractorAndSkidSteerer.png"
//then data.pageContext?.headerImage = "services/TractorAndSkidSteerer.png"
//will dislpay image file from <public?>: ../../images/services/TractorAndSkidSteerer.png
const myGetImage = (data: ComponentLocalT) => {
  //graphql contains all images, find the one set on 'headerImage' prop
  // get edge.node
  const file = data.data.allFile.edges.find((edge) => {
    const node = edge.node;
    //console.log("checking node: ", node);
    //console.log("checking node.relativePath: ", node.relativePath);
    return node.relativePath === data.pageContext?.headerImage;
  });
  //console.log("check_data myImage: ", myImage);

  const img = file ? imgTransform(file) : null;
  return img;
};

type MyImageCompnentT = { props: ComponentLocalT };

//Assumption:
// Users MUST have 2 graphQL resources:
// data at: edges.node.pageContext
//  for: pageContext.headerImage, pageContext.headerImageAlt
// file images at: allFile.edges.node.childImageSharp, allFile.edges.node.relativePath
//  for: uses graphQL to get ALL Image Files
//       then we filter on 'headerImage' to find the specific one to pick (myGetImage())
//Example usage see ServicePageComponent.tsx
export const MyImageComponent = ({ props }: MyImageCompnentT): JSX.Element | null => {
  const img = myGetImage(props);
  if (!img) return null;

  return (
    <GatsbyImage
      //see GET_IMAGE_NOTE
      image={img}
      //image.layout: "fixed" | "fullWidth" | "constrained"
      alt={props.pageContext.headerImageAlt}
      //objectFit: "contain" | "cover" | "fill" | "none" | "scale-down"
      objectFit={"scale-down"}
      style={{ height: "100%" }}
    />
  );
};
