//This Component is created dynamically through gatsby-node.js
import { Button } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import LayoutDefault from "../componentsxx/LayoutDefault";
import { phoneHref } from "../text/constants";
import { DescriptionText } from "./DescriptionText";
import SectionHeaderContainer, { SectionHeaderH1Tag, SectionHeaderPTag } from "./SectionTitle";
//only imported to infer type information
import { graphql, PageProps } from "gatsby";
import { MyImageComponent } from "./ImageHelper";
import { SEO } from "./SEO";
import { Map } from "./Map";
import { PageContextT } from "../Types/All";
import { aStyle } from "../styles/componentStyles";

type ComponentLocalT = PageProps<Queries.ServicePageQuery> & { pageContext: PageContextT };

const WorkWithUsButton = () => {
  return (
    <Row
      //unclear why row needed, but only way to get button centered
      style={
        {
          //paddingTop: 20,
        }
      }
    >
      <Col
        span={24}
        style={{
          color: "black",
          borderColor: "black",
          borderRadius: 0,
          letterSpacing: "4px",
          fontSize: "14px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "black",
            borderColor: "black",
            borderRadius: 0,
            letterSpacing: "4px",
            fontSize: "14px",
          }}
        >
          <a
            //when a user presses, open the client phone app
            href={phoneHref}
          >
            Work with Us
          </a>
        </Button>
      </Col>
    </Row>
  );
};

//Type definition ServicePageQuery comes from Named graphQL query 'ServicePage'
// 'Query' is auto appended by Gatsby api
// produced on 'gatsby develop' and saved to src/gatsby-types.d.ts
// see: graphql` query ServicePage($path: String!)

const ServicePageComponent = (data: ComponentLocalT) => {
  const { pageContext } = data;
  //console.log("check_data: ", data);
  //console.log("check_data data.pageContext: ", data.pageContext);
  //console.log("check_data data.pageContext.headerImage: ", data.pageContext?.headerImage);

  return (
    <LayoutDefault>
      <>
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <Row
            justify="center"
            style={{
              paddingTop: 64,
              paddingBottom: 64,
            }}
          >
            <Col span={24}>
              {/*
            todo: remove headerText in favor of sectionTitle
            and add prop to sectionTitle to REMOVE the underline
            <HeaderText headerText={pageContext.title} />
             */}

              <SectionHeaderContainer>
                <SectionHeaderH1Tag
                  headerText={pageContext.titleH1}
                  //to fit more characters
                  fontSize={24}
                />
              </SectionHeaderContainer>
            </Col>

            <Col span={24}>
              <Row
                justify="center"
                style={{
                  aspectRatio: "auto",
                  maxWidth: "100%",
                }}
              >
                <MyImageComponent props={data} />
              </Row>
            </Col>

            <Row
              justify="center"
              style={{
                paddingTop: 40,
                paddingBottom: 60,
              }}
            >
              <WorkWithUsButton />
            </Row>

            <Col span={24}>
              <div
              //style={{ maxWidth: `960px`, margin: `1.45rem` }}
              >
                {pageContext.paragraphs.map((para, paraIdx) => {
                  //return <li key={`content_item_${index2}`}>{data2}</li>;
                  return <DescriptionText descriptionText={para} key={`content_item_${paraIdx}`} />;
                })}
              </div>
            </Col>
            <Row
              justify="center"
              style={{
                paddingTop: 20,
                //paddingBottom: 20,
              }}
            >
              <WorkWithUsButton />
            </Row>
          </Row>

          <Row
            justify="center"
            style={{
              //paddingTop: 16,
              paddingBottom: 64,
              //paddingLeft: 64,
              //paddingRight: 64,
            }}
          >
            <Col span={24}>
              <SectionHeaderContainer>
                <SectionHeaderPTag headerText={pageContext.servicesTitle} fontSize={18} />
              </SectionHeaderContainer>

              <Row
                justify="center"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 10,
                  //height: 100,
                  //backgroundColor: "pink",
                }}
              >
                <Col span={4}></Col>
                <Col
                  //wide when small
                  xs={24}
                  //up to 1/2 width (centered) when large
                  sm={12}
                >
                  <ul>
                    {pageContext.services.map((obj, idx) => {
                      return (
                        <li>
                          {/*
                    <DescriptionText descriptionText={obj.list} key={`services_item_${idx}`} />
                     //<p> tag below is very close to <DescriptionText/>
                     //But looks better with reduced marginBottom, not 1 em but 0.5 em
                    */}
                          <p
                            style={{
                              color: "black",
                              fontSize: 15,
                              lineHeight: 1.5,
                              marginBottom: "0.5em",
                            }}
                            key={`services_item_${idx}`}
                          >
                            {obj.list}
                          </p>
                          <ul style={{ paddingLeft: 20 }}>
                            {obj.sublist &&
                              obj.sublist.map((subEl, subIdx) => (
                                <li>
                                  <DescriptionText
                                    descriptionText={subEl}
                                    key={`services_sub_item_${idx + subIdx}`}
                                  />
                                </li>
                              ))}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
                <Col span={4}></Col>
              </Row>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: 64,
            }}
          >
            <Col span={24}>
              <SectionHeaderContainer>
                <SectionHeaderH1Tag
                  headerText={pageContext.mapHeader}
                  //to fit more characters
                  fontSize={24}
                />
              </SectionHeaderContainer>
            </Col>
            {/*Google Map*/}
            <Col span={24}>
              <Map srcStr={pageContext.mapSrc} />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: 20,
              }}
            >
              <DescriptionText descriptionText={pageContext.mapDesc1} />
            </Col>

            <Col span={24}>
              <DescriptionText descriptionText={pageContext.mapDesc2} />
            </Col>
            <Row
              justify="center"
              style={{
                paddingTop: 20,
              }}
            >
              <WorkWithUsButton />
            </Row>
            {/*City Description Text*/}
            {/*Warn: some cities may not have any info*/}
            <Row>
              <Col
                span={24}
                style={{
                  paddingTop: 40,
                }}
              >
                <DescriptionText descriptionText={pageContext.articleSummary || ""} />
              </Col>
              <Col span={24}>
                <a style={{ ...aStyle, color: "black" }} href={phoneHref}>
                  {pageContext.articleURL}
                </a>
              </Col>
            </Row>
          </Row>
        </div>
      </>
    </LayoutDefault>
  );
};
export default ServicePageComponent;

export const Head = (data: { pageContext: PageContextT }) => {
  const { pageContext } = data;
  return <SEO title={pageContext.pageTitle} description={pageContext.pageDescription} />;
};

//filter restricts allSitePage results to only match this path
// if removed results will include ALL nodes/paths
//this graphql query is pulling in data setup by gatsby-node createPage() parameter: context (becomes 'pageContext')
export const query = graphql`
  query ServicePage($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          path
          pageContext
        }
      }
    }
    allFile {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          relativePath
        }
      }
    }
  }
`;
